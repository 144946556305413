import { Alert, Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getTravelRequest } from "../common/api/travelRequestApi";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { useAsyncData } from "../common/networking";
import { TravelRequestSkeleton } from "./TravelRequestSkeleton";
import { TravelRequestReviewError } from "./TravelRequestReviewError";
import { TravelFormWrapper } from "./TravelFormWrapper";

export interface TravelRequestProps {}

export const TravelRequest: React.FC<TravelRequestProps> = ({}) => {
  const { id: stringId } = useParams<{ id?: string }>();
  const id = stringId ? Number(stringId) : undefined;

  const {
    data: editableTravelRequest,
    isLoading: loadingTravelRequests,
    executeRequest: reloadRequest,
    error,
  } = useAsyncData(async () => (id ? getTravelRequest(id) : undefined), [id]);

  const [showSaved, setShowSaved] = useState(false);
  const [showError, setShowError] = useState<boolean>();

  if (error) {
    return <TravelRequestReviewError error={error} />;
  }

  if (id != null && loadingTravelRequests) {
    return <TravelRequestSkeleton />;
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" mb={2}>
          Travel Request {id ? "Review" : "Form"}
        </Typography>
      </Box>
      <TravelFormWrapper
        id={id}
        editableTravelRequest={editableTravelRequest?.travelRequest}
        reloadRequest={reloadRequest}
        canEdit={editableTravelRequest?.canEdit}
        canEstimate={editableTravelRequest?.canEstimate}
        canApprove={editableTravelRequest?.canApprove}
        canReopen={editableTravelRequest?.canReopen}
        error={() => setShowError(true)}
      />
      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
      <Snackbar
        open={showSaved}
        autoHideDuration={10000}
        onClose={() => setShowSaved(false)}
      >
        <Alert
          onClose={() => setShowSaved(false)}
          sx={{ width: "100%" }}
          elevation={6}
          variant="filled"
        >
          Form Saved
        </Alert>
      </Snackbar>
    </Box>
  );
};

import {
  approveRequest,
  estimateTravelRequestTripSegments,
  rejectRequest,
  reopenRequest,
  submitTravelRequest,
  updateTravelRequestTripSegments,
} from "../common/api/travelRequestApi";
import {
  TravelRequestSubmission,
  TripSegment,
} from "./TravelRequestSubmission";

const RECAPTCHA_ACTION = "SubmitTravelForm";

export async function estimate(
  id?: number,
  currentTravelRequest?: TravelRequestSubmission
) {
  if (!currentTravelRequest) {
    throw new Error("Tried to submit a travel request without confirming.");
  }

  if (!id) {
    throw new Error("Tried estimating non-existant request.");
  }
  await estimateTravelRequestTripSegments(id, {
    tripSegments:
      currentTravelRequest.tripSegments?.map(
        (tripSegment: TripSegment) => tripSegment.tripEstimates
      ) ?? [],
  });
}

export async function update(
  id?: number,
  currentTravelRequest?: TravelRequestSubmission
) {
  if (!currentTravelRequest) {
    throw new Error("Tried to submit a travel request without confirming.");
  }
  if (!id) {
    throw new Error("Tried updating non-existant request.");
  }
  await updateTravelRequestTripSegments(id, {
    tripSegments:
      currentTravelRequest.tripSegments?.map(
        (tripSegment: TripSegment) => tripSegment.tripSegment
      ) ?? [],
  });
}

export async function approve(id?: number) {
  if (!id) {
    throw new Error("Tried to approve a non existant travel request.");
  }
  await approveRequest(id);
}

export async function reject(id?: number, reason?: string) {
  if (!id) {
    throw new Error("Tried to reject a non existant travel request.");
  }
  await rejectRequest(id, reason ?? "");
}

export async function reopen(id?: number) {
  if (!id) {
    throw new Error("Tried to reopen a non existant travel request.");
  }
  await reopenRequest(id);
}

export async function submit(currentTravelRequest?: TravelRequestSubmission) {
  if (!currentTravelRequest) {
    throw new Error("Tried to submit a travel request without confirming.");
  }

  const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
  const token = await grecaptcha.enterprise.execute(clientKey, {
    action: RECAPTCHA_ACTION,
  });

  await submitTravelRequest(currentTravelRequest, token, RECAPTCHA_ACTION);
}

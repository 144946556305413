export interface TravelRequestSubmission {
  travelersFirstName: string;
  travelersLastName: string;
  coding: string;
  department: string;
  supervisorsFirstName: string;
  supervisorsLastName: string;
  supervisorsEmail: string;
  comments: string;
  timezone: string;
  tripType: TripTypes;
  tripSegments?: TripSegment[];
}

export interface TripSegment {
  tripSegment: TripSegmentPart;
  tripEstimates: TripSegmentEstimatePart;
}

export interface TripSegmentPart {
  startingLocation?: string;
  destination?: string;
  dateOfDeparture?: Date;
  preferredDepartureTime?: string;
  numberOfDays?: string;
  rentalRequired?: string;
  flightRequired?: string;
  hotelRequired?: string;
}

export interface TripSegmentUpdateSubmission {
  tripSegments: TripSegmentPart[];
}

export interface TripSegmentEstimatePart {
  estimatedHotelCost?: string;
  estimatedFlightCost?: string;
}

export interface TipSegmentsEstimateSubmission {
  tripSegments: TripSegmentEstimatePart[];
}

export enum TripTypes {
  oneWay = "One Way",
  roundTrip = "Round Trip",
}

import {
  TipSegmentsEstimateSubmission,
  TravelRequestSubmission,
  TripSegmentUpdateSubmission,
} from "../../TravelRequest/TravelRequestSubmission";
import { sendGet, sendPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";

export async function submitTravelRequest(
  travelRequest: TravelRequestSubmission,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  return await sendPost("/internal/api/travelRequest/add", {
    travelRequest,
    recaptchaToken,
    recaptchaAction,
  });
}

export async function getTravelRequest(id: number) {
  return await sendGet<{
    travelRequest: TravelRequestSubmission;
    canEdit: boolean;
    canEstimate: boolean;
    canApprove: boolean;
    canReopen: boolean;
  }>(`/internal/api/travelRequest/get/${id}`);
}

export async function updateTravelRequestTripSegments(
  id: number,
  travelRequest: TripSegmentUpdateSubmission
): Promise<apiResponse> {
  return await sendPost(
    `/internal/api/travelRequest/update/${id}`,
    travelRequest
  );
}

export async function estimateTravelRequestTripSegments(
  id: number,
  TipSegmentsEstimate: TipSegmentsEstimateSubmission
): Promise<apiResponse> {
  return await sendPost(
    `/internal/api/travelRequest/estimate/${id}`,
    TipSegmentsEstimate
  );
}

export async function approveRequest(id: number) {
  return await sendPost(`/internal/api/travelRequest/approve/${id}`);
}

export async function rejectRequest(id: number, reason: string) {
  return await sendPost(`/internal/api/travelRequest/reject/${id}`, reason);
}

export async function reopenRequest(id: number) {
  return await sendPost(`/internal/api/travelRequest/reopen/${id}`);
}

export interface CfiFoodProgramFormResult {
  children: CfiFoodProgramChild[];
  parent1: CfiFoodProgramParent;
  parent2: CfiFoodProgramParent;
  address: CfiFoodProgramAddress;
  groceryStore: GroceryStores;
}

export enum GroceryStores {
  coop = "Co-Op",
  northMart = "NorthMart",
  stanton = "Stanton",
}

export interface CfiFoodProgramChild {
  name: string;
  dateOfBirth: string;
  school: string;
  fileNumber: string;
  idNumber?: string;
}

export interface CfiFoodProgramParent {
  name: string;
  contactNumber: string;
  email: string;
}

export interface CfiFoodProgramAddress {
  line1?: string;
  line2?: string;
  community?: string;
  region?: string;
  country?: string;
  postalCode?: string;
}

export interface CfiFoodProgramItem {
  name: string;
  description: string;
  lengthOfTime: string;
  estimatedCost: string;
  purchaseLocation: string;
}

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { ConfirmTravelRequest } from "./ConfirmTravelRequest";
import { CloseableDialog, ConfirmCancel, Panel } from "@torqit/crew-2ls";
import { useState } from "react";
import { TripSegment } from "./TravelRequestSubmission";
import { reject } from "./TravelRequestionSubmissionFunctions";
import { useNavigate } from "react-router-dom";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import CancelIcon from "@mui/icons-material/Cancel";

export interface TravelFormPopupDialogsProps {
  id?: number;
  tripSegments: TripSegment[];
  submitFunction: () => void;
  submitting: boolean;
  setSubmitting: (val: boolean) => void;
  showConfirm: boolean;
  closeConfirm: () => void;
  showReopen: boolean;
  closeReopen: () => void;
  showReject: boolean;
  closeReject: () => void;
}

export const TravelFormPopupDialogs: React.FC<TravelFormPopupDialogsProps> = ({
  id,
  tripSegments,
  submitFunction,
  submitting,
  setSubmitting,
  showConfirm,
  closeConfirm,
  showReopen,
  closeReopen,
  showReject,
  closeReject,
}) => {
  const navigate = useNavigate();
  const [rejectReason, setRejectReason] = useState("");
  return (
    <>
      <Dialog open={showConfirm} onClose={closeConfirm}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <ConfirmTravelRequest
            submitting={submitting}
            tripSegments={tripSegments}
            onConfirm={submitFunction}
            onCancel={closeConfirm}
          />
        </DialogContent>
      </Dialog>
      <CloseableDialog
        maxWidth="sm"
        fullWidth
        open={showReopen}
        onClose={closeReopen}
      >
        <Panel title="Reopen Request?">
          <Box pb={1}>
            <Typography>
              Are you sure you want to reopen this request? It will be sent back
              to the travel department for cost estimating.
            </Typography>
          </Box>
          <ConfirmCancel
            confirmMessage={"Reopen"}
            isConfirming={false}
            icon={<SettingsBackupRestoreOutlinedIcon />}
            danger
            onConfirm={submitFunction}
            onCancel={closeReopen}
          />
        </Panel>
      </CloseableDialog>
      <CloseableDialog
        maxWidth="sm"
        fullWidth
        open={showReject}
        onClose={closeReject}
      >
        <Panel title="Reject Request?">
          <Box pb={1}>
            <TextField
              label="Why are you rejecting this request?"
              multiline
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRejectReason(event.target.value);
              }}
            />
          </Box>
          <ConfirmCancel
            confirmMessage={"Reject"}
            isConfirming={false}
            icon={<CancelIcon />}
            danger
            onConfirm={async function () {
              setSubmitting(true);
              await reject(id, rejectReason);
              navigate("/success");
            }}
            onCancel={closeReject}
          />
        </Panel>
      </CloseableDialog>
    </>
  );
};

import { FormSpy, useField } from "react-final-form";
import {
  FileFormField,
  RadioFormField,
  TextFormField,
} from "../common/components/FormFields";
import { TypesOfSupport, Vendor } from "./CfiWinterClothingTypes";
import { Box, Grid, Typography } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import { useEffect } from "react";

const dotty = require("dotty");

export interface WinterClothingPurchaseFieldsProps { }

export function WinterClothingPurchaseFields({ }: WinterClothingPurchaseFieldsProps) {

  const { input: { value: typeOfSupport } } = useField<TypesOfSupport>("typeOfSupport");
  const { input: { onChange: onVendorChange } } = useField<Vendor>("vendor");

  useEffect(() => {

    if (typeOfSupport !== TypesOfSupport.BulkPO) {

      onVendorChange(undefined);
    }

  }, [typeOfSupport])


  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Purchases</Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <RadioFormField
            name="typeOfSupport"
            label="Reimbursement Method"
            options={Object.values(TypesOfSupport)}
          />
        </Grid>
        {TypesOfSupport.BulkPO === typeOfSupport && (
          <Grid item sm={12}>
            <RadioFormField
              icon={<StoreIcon />}
              name="vendor"
              label="Vendor"
              options={Object.values(Vendor)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

import { useNavigate } from "react-router-dom";
import { useRecaptchaReady } from "../common/hooks/useRecaptchaReady";
import { useState } from "react";
import arrayMutators from "final-form-arrays";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { Form } from "react-final-form";
import { Box, Stack, Typography } from "@mui/material";
import { CheckboxFormField } from "../common/components/FormFields";
import { SubmitButton } from "../common/components/SubmitButton";
import {
  CfiFoodProgramChild,
  CfiFoodProgramFormResult,
} from "./CfiFoodProgramTypes";
import {
  DEFAULT_CHILD_FIELDS,
  FoodProgramChildrenFields,
} from "./FoodProgramChildrenFields";
import { CfiFoodProgramHeader } from "./CfiFoodProgramHeader";
import { FoodProgramParentFields } from "./FoodProgramParentFields";
import { FoodProgramPurchaseFields } from "./FoodProgramPurchaseFields";
import { submitCfiFoodProgramApplication } from "../common/api/cfiFoodProgramApplicationApi";

const RECAPTCHA_ACTION = "SubmitCfiFoodProgramApplication";

//Declare this outside so that it's the same object being passed to the
// initial values each time instead of recreating it on each render
const initialValues: Partial<CfiFoodProgramFormResult> = {
  children: [{ ...(DEFAULT_CHILD_FIELDS as CfiFoodProgramChild) }],
  parent1: {
    name: "",
    contactNumber: "",
    email: "",
  },
  parent2: {
    name: "",
    contactNumber: "",
    email: "",
  },
  address: {
    line1: "",
    line2: "",
    community: "",
    region: "Northwest Territories",
    country: "Canada",
    postalCode: "",
  },
  groceryStore: undefined,
};

export interface CfiFoodProgramFormProps {}

export function CfiFoodProgramForm({}: CfiFoodProgramFormProps) {
  const recaptchaReady = useRecaptchaReady();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);

  const submit = async (result: CfiFoodProgramFormResult) => {
    try {
      const clientKey = process.env.REACT_APP_RECAPTCHA_CLIENT_KEY ?? "";
      const token = await grecaptcha.enterprise.execute(clientKey, {
        action: RECAPTCHA_ACTION,
      });

      await submitCfiFoodProgramApplication(result, token, RECAPTCHA_ACTION);

      navigate("/success");
    } catch {
      setShowError(true);
    }
  };

  return (
    <>
      <Form<CfiFoodProgramFormResult>
        onSubmit={submit}
        subscription={{ submitting: true }}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <CfiFoodProgramHeader />
              <FoodProgramParentFields />
              <FoodProgramChildrenFields />
              <FoodProgramPurchaseFields />
              <Box>
                <Typography variant="h5">Declaration and Consent</Typography>
              </Box>
              <CheckboxFormField
                name="authorizeConsent"
                mustBeTrue
                label="By checking this box, I consent to IRC using and collecting identifying information for myself and my child(ren) to validate the information provided in this form, and confirm eligibility to participate in this initiative. This information includes Inuvialuit file numbers, names, dates of birth, phone numbers, email addresses and mailing addresses. I further consent to and acknowledge that IRC may disclose my personal information and the personal information of my child(ren) internally to its divisions for the sole purpose of assessing this application."
              />
              <CheckboxFormField
                name="confirmNotApproval"
                mustBeTrue
                label="I certify that the information I have provided is true and accurate. I understand that this form is not an automatic approval and that I will receive communication once a decision has been made. I further understand that providing false or misleading information on this form may lead to my application being rejected."
              />
              <Box ml={5} mt={3}>
                <SubmitButton
                  type="submit"
                  disabled={!recaptchaReady}
                  isSubmitting={submitting}
                />
              </Box>
            </Stack>
          </form>
        )}
      />
      <ErrorNotification open={showError} onClose={() => setShowError(false)} />
    </>
  );
}

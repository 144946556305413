import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { TripSegment } from "./TravelRequestSubmission";

export interface ConfirmTravelRequestProps {
  tripSegments: TripSegment[];
  submitting: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

export function ConfirmTravelRequest({
  tripSegments,
  submitting,
  onConfirm,
  onCancel,
}: ConfirmTravelRequestProps) {
  return (
    <Box>
      <Typography>Please confirm the following:</Typography>
      <ul>
        {tripSegments.map((s) => (
          <>
            <Typography component="li">
              <Typography component="span" variant="body2">
                {dayjs(s.tripSegment.dateOfDeparture).format("ll")}
              </Typography>{" "}
              - {s.tripSegment.flightRequired === "Yes" ? "Flight" : "Travel"}{" "}
              from <b>{s.tripSegment.startingLocation ?? "[Not Specified]"}</b>{" "}
              to <b>{s.tripSegment.destination ?? "[Not Specified]"}</b>{" "}
            </Typography>
            <Typography component="li">
              <Typography component="span" variant="body2">
                {dayjs(s.tripSegment.dateOfDeparture).format("ll")} to{" "}
                {dayjs(s.tripSegment.dateOfDeparture)
                  .add(Number(s.tripSegment.numberOfDays ?? 0), "days")
                  .format("ll")}
              </Typography>{" "}
            </Typography>
          </>
        ))}
      </ul>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        gap={2}
        pt={2}
      >
        <Button
          disabled={submitting}
          variant="contained"
          color="success"
          onClick={onConfirm}
        >
          Confirm
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Stack>
    </Box>
  );
}

import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DateFormField,
  SelectFormField,
  TextFormField,
} from "../common/components/FormFields";
import { MuiPerson } from "../common/icons";
import dayjs from "dayjs";
import { FieldArray } from "react-final-form-arrays";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ErrorNotification } from "../common/components/ErrorNotification";
import { useState } from "react";
import { CfiFoodProgramChild } from "./CfiFoodProgramTypes";
import { SelectAddableFormField } from "../common/components/FormFields/SelectAddableFormField";
import { useFieldValue } from "../common/hooks/useFieldValue";
import { useBreakpoints } from "../common/hooks/useBreakpoints";

const schools = [
  "Angik",
  "Child Development Centre",
  "Children First Centre",
  "East Three Elementary",
  "East Three Secondary",
  "Head Start",
  "Helen Kalvak",
  "Inualthuyak",
  "Mangilaluk",
  "Moose Kerr",
];

interface FoodProgramChildFieldsProps {
  namespace: string;
}

const OTHER = "Other";

function FoodProgramChildFields({ namespace }: FoodProgramChildFieldsProps) {
  const { md, lg } = useBreakpoints();
  const school = useFieldValue<string>(`${namespace}.school`);

  return (
    <Grid container spacing={0.5} flex={1}>
      <Grid item lg={4} xs={12}>
        <TextFormField
          name={`${namespace}.name`}
          label="Name"
          required
          icon={<MuiPerson />}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <DateFormField
          name={`${namespace}.dateOfBirth`}
          label="Date Of Birth"
          required
          stringOutput
          noIcon={lg}
          minDate={dayjs().subtract(20, "years")}
        />
      </Grid>
      <Grid item lg={4} xs={12}>
        <TextFormField
          name={`${namespace}.fileNumber`}
          label="File Number"
          noIcon={lg}
        />
      </Grid>
      <Grid item md={school === OTHER ? 6 : 12} xs={12}>
        <SelectFormField
          name={`${namespace}.school`}
          label="ISR School or Daycare"
          options={[
            ...schools,
            { text: "Other Licensed Daycare", value: OTHER },
          ]}
          required
        />
      </Grid>
      {school === OTHER && (
        <Grid item md={6} xs={12}>
          <TextFormField
            name={`${namespace}.customSchool`}
            label="Custom Licensed Daycare"
            required
            noIcon={md}
          />
        </Grid>
      )}
    </Grid>
  );
}

export const DEFAULT_CHILD_FIELDS: Partial<CfiFoodProgramChild> = {
  name: "",
  idNumber: "",
  fileNumber: "",
  school: "",
};

export interface FoodProgramChildrenFieldsProps {}

export function FoodProgramChildrenFields({}: FoodProgramChildrenFieldsProps) {
  const [showError, setShowError] = useState(false);
  return (
    <>
      <Box>
        <Box pb={1}>
          <Typography variant="h5">Children</Typography>
        </Box>
        <FieldArray<Partial<CfiFoodProgramChild>>
          name="children"
          render={({ fields }) => (
            <Stack gap={1}>
              {fields.map((name, index) => (
                <Stack direction="row" alignItems="center" gap={1}>
                  <FoodProgramChildFields namespace={name} />
                  <IconButton
                    color="error"
                    onClick={() => fields.remove(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              ))}
              <Box ml={5}>
                <Button
                  startIcon={<AddIcon />}
                  variant="outlined"
                  onClick={() => {
                    if ((fields?.length ?? 0) >= 10) {
                      setShowError(true);
                    } else {
                      fields.push({ ...DEFAULT_CHILD_FIELDS });
                    }
                  }}
                >
                  Add Child
                </Button>
              </Box>
            </Stack>
          )}
        />
      </Box>
      <ErrorNotification
        open={showError}
        onClose={() => setShowError(false)}
        message="You cannot add more than 10 children to this application"
      />
    </>
  );
}

import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleIcon from "@mui/icons-material/Circle";

export interface CfiFoodProgramHeaderProps {}

export function CfiFoodProgramHeader({}: CfiFoodProgramHeaderProps) {
  return (
    <Box>
      <Typography variant="h4" paragraph>
        ISR School Foods Program
      </Typography>
      <Typography paragraph>
        The ISR School Food Funding Plan aims to promote health, wellness, and
        food security among Inuvialuit beneficiary students by providing
        consistent access to nutritious foods across eligible ISR schools and
        child care centers. Through structured funding distribution, stringent
        reporting, and community engagement, the program ensures that every
        eligible student receives the nutritional support they need to thrive.
      </Typography>
      <Typography variant="h5">Eligibility Checklist</Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            <CircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Must be 0-18 years old " />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Must be attending ISR school or licensed day care (2024 Registered)" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Child(ren) must be registered under the Inuvialuit Final Agreement, to register your child(ren) please contact enrolment@inuvialuit.com" />
        </ListItem>
      </List>
      <Typography variant="h5">Funding Details</Typography>
      <Typography paragraph>
        Please note that the cutoff dates to apply is the first Friday of the
        month. All funding requests must be submitted by this time to receive
        funding that month IRC will not be responsible for any charges incurred
        beyond the allocated funding amount.
      </Typography>
    </Box>
  );
}

import { Box, Button, Divider, FormLabel, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { MuiAdd } from "../common/icons";
import { FieldArray, useFieldArray } from "react-final-form-arrays";
import { TripSegmentFields } from "./TripSegmentFields";
import { RadioFormField } from "../common/components/FormFields";
import { useForm, useFormState } from "react-final-form";
import { TravelRequestSubmission, TripTypes } from "./TravelRequestSubmission";
import dayjs from "dayjs";

export interface TripSegmentProps {
  disabled?: boolean;
  canSeeEstimate?: boolean;
  canEstimate?: boolean;
}

export const TripSegmentsSection: React.FC<TripSegmentProps> = ({
  disabled,
  canSeeEstimate,
  canEstimate,
}) => {
  const {
    values: { tripType, tripSegments },
  } = useFormState<TravelRequestSubmission>({ subscription: { values: true } });

  const { fields } = useFieldArray("tripSegments");

  useEffect(() => {
    if (tripType === TripTypes.roundTrip && tripSegments?.length == 1) {
      fields.push({});
    }
  }, [tripType, tripSegments]);

  return (
    <Box>
      <Box mb={0.5}>
        <FormLabel>Trip Segments</FormLabel>
      </Box>

      <RadioFormField
        name={`tripType`}
        label="Trip Type"
        options={[TripTypes.oneWay, TripTypes.roundTrip]}
        noIcon
        disabled={disabled}
        required={true}
      />

      <FieldArray
        name="tripSegments"
        render={({ fields, meta }) => (
          <Box>
            <Stack gap={2} divider={<Divider />}>
              {fields.map((name, i) => (
                <TripSegmentFields
                  name={name}
                  index={i}
                  disabled={disabled}
                  canSeeEstimate={canSeeEstimate}
                  canEstimate={canEstimate}
                  removable={
                    tripType === TripTypes.roundTrip
                      ? (fields?.length ?? 0) > 2
                      : (fields.length ?? 0) > 1
                  }
                  removeSegment={() => {
                    fields.remove(i);
                  }}
                />
              ))}
            </Stack>
            {!disabled && (
              <Box py={2}>
                <Button
                  startIcon={<MuiAdd />}
                  variant="contained"
                  onClick={() =>
                    fields.push({ tripSegment: {}, tripEstimates: {} })
                  }
                >
                  Add
                </Button>
              </Box>
            )}
          </Box>
        )}
      />
    </Box>
  );
};

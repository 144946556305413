import {
  TextField,
  Box,
  CircularProgress,
  MenuItem,
  TextFieldProps,
} from "@mui/material";
import React, { ChangeEventHandler } from "react";
import { Field } from "react-final-form";
import { FormFieldWrapper, FormFieldWrapperProps } from "./FormFieldWrapper";

export interface SelectFormFieldOption {
  value?: number | string;
  text: number | string;
}

export interface SelectFormFieldProps
  extends Omit<FormFieldWrapperProps, "children"> {
  name: string;
  label?: string;
  isLoading?: boolean;
  required?: boolean;
  size?: TextFieldProps["size"];
  disabled?: boolean;
  forceError?: boolean;
  options?: (SelectFormFieldOption | string)[];
  /** @deprecated For extracting data from inputs, consider using <FormSpy> or
   * useFormState() */
  changeFn?: ChangeEventHandler<Element>;
}

export const SelectFormField: React.FC<SelectFormFieldProps> = ({
  name,
  label,
  required,
  isLoading,
  options,
  forceError,
  size,
  disabled,
  changeFn,
  ...wrapperProps
}) => {
  return (
    <FormFieldWrapper {...wrapperProps}>
      <Field
        name={name}
        validate={(v) =>
          required && v == null ? "This field is required" : undefined
        }
        render={({ input, meta }) => (
          <TextField
            label={label}
            onChange={changeFn ? changeFn : () => {}}
            select
            required={required}
            fullWidth
            inputProps={input}
            error={(meta.touched && meta.error) || forceError}
            helperText={!forceError || (meta.touched && meta.error)}
            size={size}
            disabled={disabled ?? false}
            InputProps={{
              endAdornment: isLoading ? (
                <Box mr={3}>
                  <CircularProgress size={20} />
                </Box>
              ) : undefined,
            }}
          >
            {options &&
              options.map((d) => (
                <MenuItem
                  key={typeof d === "string" ? d : d.value}
                  value={typeof d === "string" ? d : d.value}
                >
                  {typeof d === "string" ? d : d.text}
                </MenuItem>
              ))}
            {!options && <MenuItem>Empty!</MenuItem>}
          </TextField>
        )}
      />
    </FormFieldWrapper>
  );
};

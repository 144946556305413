export interface CfiWinterClothingFormResult {
  children: CfiWinterClothingChild[];
  parent1: CfiWinterClothingParent;
  parent2: CfiWinterClothingParent;
  address: CfiWinterClothingAddress;
  typeOfSupport: TypesOfSupport;
  vendor: Vendor;
  purchaseFiles: File[];
}

export enum TypesOfSupport {
  BulkPO = "Bulk Purchase Order (North Mart, Just Raven, etc.)",
  WinnipegOutfitters = "Winnipeg Outfitters",
  reimbursement = "Reimbursement (Receipt will be required)",
}

export enum Vendor {
  HawkesSupply = "Hawks Supply",
  InuvikNorthMart = "Inuvik Northmart",
  JustRaven = "Just Raven",
}

export interface CfiWinterClothingChild {
  name: string;
  dateOfBirth: string;
  idNumber?: string;
}

export interface CfiWinterClothingParent {
  name: string;
  contactNumber: string;
  email: string;
}

export interface CfiWinterClothingAddress {
  line1?: string;
  line2?: string;
  community?: string;
  region?: string;
  country?: string;
  postalCode?: string;
}

export interface CfiWinterClothingItem {
  name: string;
  description: string;
  lengthOfTime: string;
  estimatedCost: string;
  purchaseLocation: string;
}

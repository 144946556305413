import { FormSpy } from "react-final-form";
import {
  FileFormField,
  RadioFormField,
  TextFormField,
} from "../common/components/FormFields";
import { Box, Grid, Typography } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import { GroceryStores } from "./CfiFoodProgramTypes";

const dotty = require("dotty");

export interface FoodProgramPurchaseFieldsProps {}

export function FoodProgramPurchaseFields({}: FoodProgramPurchaseFieldsProps) {
  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h5">Purchases</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <RadioFormField
            name="groceryStore"
            label="Grocery Store"
            options={Object.values(GroceryStores)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FormSpy, useField } from "react-final-form";
import { TextFormField } from "../common/components/FormFields";
import { AutocompleteFormField } from "../common/components/FormFields/AutocompleteFormField";

export interface FoodProgramAddressFieldsProps {
  namespace: string;
  label?: string;
  required?: boolean;
}

export function FoodProgramAddressFields({
  namespace,
  required,
  label,
}: FoodProgramAddressFieldsProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container spacing={0.5} pt={1}>
      {label && (
        <Grid item xs={12}>
          <Box ml={5}>
            <Typography>{label}</Typography>
          </Box>
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.line1`}
          required={required}
          label="Line 1"
          size="small"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.line2`}
          label="Line 2"
          size="small"
          noIcon={md}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.country`}
          disabled
          required={required}
          value={"Canada"}
          label="Country"
          size="small"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <AutocompleteFormField
          name={`${namespace}.community`}
          required={required}
          label="Community"
          options={[
            "Aklavik",
            "Inuvik",
            "Paulatuk",
            "Sachs Harbour",
            "Tuktoyaktuk",
            "Ulukhaktok",
          ]}
          size="small"
          noIcon={md}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <FormSpy
          subscription={{ values: true }}
          render={({ values }) => (
            <TextFormField
              name={`${namespace}.region`}
              required={required}
              disabled
              label={"Province/Territory"}
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`${namespace}.postalCode`}
          label="Postal Code"
          required={required}
          size="small"
          noIcon={md}
        />
      </Grid>
    </Grid>
  );
}

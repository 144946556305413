import { CfiFoodProgramFormResult } from "../../FoodsProgramForm/CfiFoodProgramTypes";
import { sendFormDataPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";
import { serialize } from "object-to-formdata";

export async function submitCfiFoodProgramApplication(
  application: CfiFoodProgramFormResult,
  recaptchaToken: string,
  recaptchaAction: string
): Promise<apiResponse> {
  const formData = serialize(
    { application, recaptchaToken, recaptchaAction },
    { indices: true }
  );

  return await sendFormDataPost("/api/cfi-food-program-application", formData);
}

import { Link, Grid, Stack } from "@mui/material";
import React from "react";

const Dashboard: React.FC = () => {
  return (
    <Stack spacing={4}>
      <Link href="/internal/cfi-winter-clothing-application-export" download>
        Export CFI Winter Clothing Applications
      </Link>
      <Link href="/internal/food-program-application-export" download>
        Export School Foods Program Applications
      </Link>
    </Stack>
  );
};

export default Dashboard;
